import { lazy } from 'react';
import { FallBackLoading } from './components/FallbackLoading/FallbackLoading';

const RouteLoadingComponent = <FallBackLoading />;

const Home = lazy(() => import('./pages/Home/Home'));

interface IRoutes {
  path: string;
  exact: boolean;
  component: React.LazyExoticComponent<(props: any) => JSX.Element>;
  props?: any;
  fallback: JSX.Element;
}

const routes: IRoutes[] = [
  {
    path: '/',
    exact: true,
    component: Home,
    props: { isHome: true },
    fallback: RouteLoadingComponent,
  },
  // Add more routes as needed
];

export default routes;
