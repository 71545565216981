import { IAction, IInitialConfiguration } from "../types";
import { configurationInitialState } from "./initialState";

const initialState = configurationInitialState;

export const configurationReducer = (state = initialState, action: IAction): IInitialConfiguration => {
  switch(action.type) {
    default:
      return state;
  }
}