import { SagaIterator } from "redux-saga";
import { takeLatest } from "redux-saga/effects";

export function* getXyz(): SagaIterator {
  // try {
  //   const response = yield call(fn);
  //   yield put(storaction);
  // } catch(error) {
  //   const errorResp = parseError(error);
  //   yield put(errorAction);
  // }
}

function* watchConfiguration(): Generator {
  // yield takeLatest(CONSTANTS.XYZ, getXyz);
}

export default watchConfiguration;