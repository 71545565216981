import { combineReducers } from "redux";
import { configurationReducer } from "../../hoc/Configuration/store/reducers";

const rootReducer = combineReducers({
  configuration: configurationReducer
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
