import { Configuration } from './hoc/Configuration/Configuration';
import { Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import appRoutes from './routes';

function App() {
  return (
    <>
      <Configuration>
        <Routes>
          {appRoutes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              element={
                <Suspense fallback={route.fallback}>
                  <route.component {...route.props} />
                </Suspense>
              }
            />
          ))}
        </Routes>
      </Configuration>
    </>
  );
}

export default App;
